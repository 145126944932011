@mixin slideAnimation(
  $name,
  $time,
  $ease-function: "linear",
  $direction: "normal",
  $delay: 0
) {
  animation-name: $name;
  animation-duration: $time;
  animation-iteration-count: 1;
  animation-direction: $direction;
  animation-timing-function: $ease-function;
  animation-delay: $delay;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fromTop {
  from {
    transform: translate3d(0, -100%, -200px);
  }
  to {
    transform: translate3d(0);
  }
}

@keyframes fromBottom {
  from {
    transform: translate3d(0, 100%, -200px);
  }
  to {
    transform: translate3d(0);
  }
}
