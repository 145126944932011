@use "./animations";
@use "./typography";

.main-menu {
  grid-area: main-menu;

  // Global
  a {
    display: block;
    cursor: pointer;

    letter-spacing: 0.04em;

    transition: color 2s ease-out;
  }

  .optional {
    display: none;
  }

  a:hover,
  a:focus {
    color: var(--primary-color);

    background: linear-gradient(
      45deg,
      var(--primary-color),
      var(--secondary-color),
      var(--primary-color)
    );
    background-size: 400% 400%;

    background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;

    animation-name: gradientSpin;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes gradientSpin {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  /// Portrait devices (mobile)
  @media (orientation: portrait) {
    /// Animation
    @include animations.slideAnimation(fromRight, 1s, ease-out);

    // line-height: 30px;
    text-transform: uppercase;
    text-align: right;
    padding: 16px 16px 0 0;

    li {
      @include typography.menuTypographyMobile;
    }

    li:not(:last-of-type) {
      margin-bottom: .5em;
    }

    a {
      display: inline-block;
    }
  }

  /// Desktop devices
  @media (orientation: landscape) {
    /// Animation
    opacity: 0;
    @include animations.slideAnimation(fadeIn, 1.5s, linear, normal, 1s);

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-end;

    padding: 0 0 8px 16px;

    font-size: 0.85em;

    ul {
      display: inline-flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: flex-start;

      li {
        order: 0;
        flex: 0 1 auto;
        align-self: auto;
        margin-right: 3em;
      }
    }

    a {
      line-height: 133.2%;
      padding: 8px 0;
    }

    // Bigger screens
    @media (min-width: 1200px) {
      padding: 0 0 32px 32px;
    }

    /// Height
    @media (min-height: 600px) {
      /// Fluid typography
      --minFontSize: 16px;
      --maxFontSize: 200px;
      --scaler: 1vw;
      font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));

      .optional {
        display: initial;
      }
    }
  }
}
