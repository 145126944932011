.portrait {
  grid-area: portrait;

  background-color: var(--primary-color);
  position: relative;

  text-align: center;

  picture {
    display: flex;
    position: absolute;
    z-index: 1;
    // bottom: 0;
    width: 100%;
    height: 100%;
    // left: 0;
    transform: scale(1.2);
    transform-origin: bottom center;

    > img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-position: center bottom;
    }
  }

  /// Desktop devices
  @media (orientation: landscape) {
    picture {
      transform: scale(1.1);
    }
  }
}
