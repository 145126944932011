@mixin menuTypographyMobile {
  font-size: 1.125em;
  line-height: 133.24%;

  /// Fluid typography
  --minFontSize: 16px;
  --maxFontSize: 64px;
  --scaler: 2.85vh;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
}
