@use "modules/header-1";
@use "modules/header-2";
@use "modules/call-to-action";
@use "modules/cookie-consent";
@use "modules/main-menu";
@use "modules/portrait";
@use "modules/animations";

:root {
  font-family: "Epilogue", sans-serif;
  font-weight: 700;

  --main-bg-color: #fffaff;
  --primary-color: #fe4a49;
  --secondary-color: #52489c;
  --text-color: #171717;

  -webkit-text-size-adjust: none;
}

// Note the * after the class name. This just selects everything after the initial
// class definition
.preload-transitions * {
  // Dry this up a bit with SCSS variable
  $null-transition: none !important;

  -webkit-transition: $null-transition;
  -moz-transition: $null-transition;
  -ms-transition: $null-transition;
  -o-transition: $null-transition;
  transition: $null-transition;
}

html {
  height: -webkit-fill-available;
}

body {
  background-color: var(--main-bg-color);
  color: var(--text-color);
}

h1, h2 {
  cursor: default;
  user-select: none;
}

.wrapper {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);

  overflow: hidden;
  position: relative;

  display: grid;
  grid-gap: 0;
}

@media (orientation: portrait) {
  .wrapper {
    // position: fixed;
    width: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: 34% 20% 16% auto; // 34% 66%
    grid-template-areas:
      "call-to-action main-menu"
      "header-1 header-1"
      "header-2 header-2"
      "portrait portrait";
  }
}

@media (orientation: landscape) {
  .wrapper {
    grid-template-columns: 66% auto;
    grid-template-rows: 25% 15% 40% auto;
    grid-template-areas:
      "header-1 header-2"
      "header-1 portrait"
      "call-to-action portrait"
      "main-menu portrait";
  }
}
