@use "./animations";

.header__2 {
  grid-area: header-2;
  background-color: var(--primary-color);

  /// Portrait devices (mobile)
  @media (orientation: portrait) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: flex-start;
    padding-top: 4px;

    // Little hack to avoid white gap
    position: relative;
    bottom: -1px;

    img {
      height: auto;
      width: 66%;
      vertical-align: top;

      @include animations.slideAnimation(fromTop, 1.25s, ease-out);
      z-index: 0;
    }
  }

  /// Desktop devices
  @media (orientation: landscape) {
    padding: 16px 16px 0 16px;
    overflow: hidden;
    text-align: center;

    img {
      width: auto;
      max-width: 100%;
      max-height: 100%;
      display: inline-block;

      @include animations.slideAnimation(fromLeft, 1.25s, ease-out);
    }

    /// Bigger screens
    @media (min-width: 1200px) {
      padding: 32px 32px 0 32px;

      img {
        height: 100%;
      }
    }
  }
}
