.cookie-consent {
  position: absolute;
  // top: 0;
  color: #171717;
  z-index: 3;

  &::before {
    content: "";
    width: 2em;
    height: 2em;

    display: inline-block;
    vertical-align: middle;
    background-image: url("/src/images/cookies.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
  }

  /// Portrait devices (mobile)
  @media (orientation: portrait) {
    font-size: 0.688em;

    bottom: 0px;
    // right: -16px;
    left: calc(100% - 18px);
    width: 100%;
    transform: rotate(-90deg);
    transform-origin: center left;

    &::before {
      transform: rotate(90deg);
      margin-right: .75em;
    }
  }

  /// Desktop devices
  @media (orientation: landscape) {
    bottom: 12px;
    right: 12px;

    font-size: .625em;
    line-height: 200%;

    &::before {
      transform: rotate(90deg);
      margin-right: .75em;
      width: 2em;
      height: 2em;
    }

    // Bigger screens
    @media (min-width: 1200px) {
      bottom: 32px;
      right: 32px;
    }

    /// Height
    @media (min-height: 600px) {
      /// Fluid typography
      --minFontSize: 12px;
      --maxFontSize: 32px;
      --scaler: 0.8vw;
      font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));

      &::before {
        width: 2.5em;
        height: 2.5em;
      }
    }
  }
}
