@use "./animations";

.header__1 {
  grid-area: header-1;

  @media (orientation: portrait) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: flex-end;

    img {
      height: auto;
      width: calc(100% - 32px);
      vertical-align: top;

      position: relative;
      bottom: -2px;

      @include animations.slideAnimation(fromBottom, 1s, ease-out);
      z-index: 2;
    }
  }

  @media (orientation: landscape) {
    padding: 16px 16px 0 16px;
    overflow: hidden;

    img {
      width: auto;
      max-width: 100%;
      max-height: 100%;

      @include animations.slideAnimation(fromRight, 2s, ease-out);
    }

    /// Bigger screens
    @media (min-width: 1200px) {
      padding: 32px 32px 0 32px;

      img {
        height: 100%;
      }
    }
  }
}
