@use "./animations";
@use "./typography";

.call-to-action {
  grid-area: call-to-action;

  &__btn {
    letter-spacing: 0.03em;

    span {
      pointer-events: none;
    }
  }

  /// Portrait devices (mobile)
  @media (orientation: portrait) {
    /// Animation
    @include animations.slideAnimation(fromLeft, 1s, ease-out);

    padding: 16px 0 0 16px;

    &__btn {
      text-transform: uppercase;
      color: var(--secondary-color);

      @include typography.menuTypographyMobile;

      &::before {
        content: "";
        width: 1.25em;
        height: 1.25em;

        margin-right: .2em;
        display: inline-block;
        vertical-align: middle;
        background-image: url("/src/images/message.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
      }

      span:nth-of-type(1) {
        display: none;
      }

      span:nth-of-type(2) {
        display: none;
      }
    }

    &__tag-line {
      display: none;
    }

    @media (min-width: 320px) {
      &__btn {
        span:nth-of-type(1) {
          display: initial;
        }
      }
    }
  }

  /// Portrait devices (desktop)
  @media (orientation: landscape) {
    // Animation
    opacity: 0;
    @include animations.slideAnimation(fadeIn, 1.5s, linear, normal, 0.5s);

    padding: 16px 0 0 16px;

    h2 {
      text-transform: uppercase;
      color: var(--secondary-color);

      font-size: 1.5em;

      line-height: 133.24%;
      margin-bottom: 16px;
    }

    &__btn {
      display: none;

      // Responsive
      height: 3.5em;
      width: 14em;

      text-transform: uppercase;
      border: solid 2px var(--secondary-color);
      color: var(--secondary-color);

      text-align: center;
      position: relative;

      cursor: pointer;

      /// Text replacement effect
      span {
        position: absolute;
        transition: transform 0.25s ease-in, opacity 0.25s linear;
        line-height: 100%;
        margin-top: 0.26em;
      }

      span:nth-of-type(1) {
        opacity: 1;
      }

      span:nth-of-type(2) {
        opacity: 0;
        transform: translateY(200%);
      }

      &:hover {
        span:nth-of-type(1) {
          opacity: 0;
          transform: translateY(-200%);
        }

        span:nth-of-type(2) {
          opacity: 1;
          transform: translateY(0);
        }
      }

      /// Gradient effect
      &:before {
        content: "";
        background: linear-gradient(
          45deg,
          var(--primary-color),
          var(--secondary-color),
          var(--primary-color)
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing 20s linear infinite;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }

      &:active {
        color: var(--main-bg-color);
      }

      &:active:after {
        background: transparent;
      }

      &:hover:before {
        opacity: 1;
      }

      &:after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--main-bg-color);
        left: 0;
        top: 0;
      }

      @keyframes glowing {
        0% {
          background-position: 0 0;
        }
        50% {
          background-position: 400% 0;
        }
        100% {
          background-position: 0 0;
        }
      }
    }

    /// Bigger screens
    @media (min-width: 1200px) {
      padding: 32px 0 0 32px;
    }

    /// Height
    @media (min-height: 420px) {
      &__btn {
        // Center inner elements
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: stretch;
        align-items: center;
      }
    }

    @media (min-height: 600px) {
      h2 {
        /// Fluid typography
        --minFontSize: 32px;
        --maxFontSize: 200px;
        --scaler: 3vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
      }

      &__btn {
        /// Fluid typography
        --minFontSize: 16px;
        --maxFontSize: 200px;
        --scaler: 1.15vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
      }
    }
  }
}
